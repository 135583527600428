@import "react-phone-input-2/lib/style.css";
.layout-one {
  height: 100vh;
}
.layout-one .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  padding: 5px 40px;
  height: 54px;
  padding-top: 8px;
  padding-left: 42px;
}
.layout-one .header .left .mob-menu {
  display: none;
}
.layout-one .header .left img {
  max-width: 105px;
}
.layout-one .header .right ul {
  display: flex;
  align-items: center;
  gap: 26px;
  list-style: none;
}
.layout-one .header .right ul li {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  color: rgb(33, 33, 33);
}
.layout-one .header .right ul li .icon {
  width: 22px;
  height: 22px;
  color: rgba(33, 33, 33, 0.8);
}
.layout-one .header .right ul li .icon:hover {
  color: #0034bb;
}
.layout-one .header .right ul li .selected {
  color: #0034bb;
}
.layout-one .header .right ul li .avatar {
  width: 25px;
  height: 25px;
  font-size: 15px;
}
.layout-one .page {
  display: flex;
  align-items: center;
  background: #fdfdfd;
  gap: 30px;
  background: #fdfdfd;
}
.layout-one .page .right {
  height: calc(100vh - 54px);
  width: 100%;
  padding-right: 40px;
  overflow: auto;
}

.sidebar {
  position: relative;
  width: 281px;
  padding-right: 1px;
  background: #ffffff;
  border-right: none;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2) 33%, rgba(255, 255, 255, 0) 0%);
  background-position: right;
  background-size: 1.4px 10px;
  background-repeat: repeat-y;
  transition: width 0.3s ease; /* Add transition property for smooth width changes */
}
.sidebar .switch {
  position: absolute;
  z-index: 1;
  right: -16px;
  cursor: pointer;
  top: 10px;
}
.sidebar .switch .switch-icon {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 24%;
  left: 24%;
}
.sidebar .expended-view {
  height: calc(100vh - 54px);
  overflow-y: scroll;
}
.sidebar .expended-view .item {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 20px;
  width: 280px;
  overflow-x: scroll;
}
.sidebar .expended-view .item_::-webkit-scrollbar {
  width: none;
  width: 0px;
}
.sidebar .expended-view .item .top-head {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-left: 40px;
  height: 55px;
  width: 280px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.6);
  cursor: pointer;
}
.sidebar .expended-view .item .top-head .icons {
  width: 24px;
  height: 24px;
  color: rgba(33, 33, 33, 0.6);
}
.sidebar .expended-view .item .top-head a {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.6);
  cursor: pointer;
}
.sidebar .expended-view .item .top-head:hover {
  background: rgba(0, 52, 187, 0.1);
  color: #0034bb;
}
.sidebar .expended-view .item .top-head:hover .icons {
  color: #0034bb;
}
.sidebar .expended-view .item .top-head:hover a {
  color: #0034bb;
}
.sidebar .expended-view .item .active {
  border-right: 4px solid #0034bb;
  background: rgba(0, 52, 187, 0.1);
  color: #0034bb;
}
.sidebar .expended-view .item .active .icons {
  color: #0034bb;
}
.sidebar .expended-view .item .active a {
  color: #0034bb;
}
.sidebar .expended-view .item .menu {
  box-shadow: none !important;
  border: none !important;
  margin-bottom: 0px;
  min-width: 280px;
}
.sidebar .expended-view .item .menu ::before {
  height: 0px !important;
}
.sidebar .expended-view .item .menu .list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px;
}
.sidebar .expended-view .item .menu .list .a {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.6);
  padding: 8px 40px;
  padding-left: 77px;
  cursor: pointer;
}
.sidebar .expended-view .item .menu .list .a:hover {
  padding: 8px 38px;
  padding-left: 77px;
  background: rgba(0, 52, 187, 0.1);
  color: #0034bb;
}
.sidebar .expended-view .item .menu .list .active1 {
  border-right: 4px solid #0034bb;
  background: rgba(0, 52, 187, 0.1);
  color: #0034bb;
}
.sidebar .expended-view .item .menu .MuiButtonBase-root {
  padding-left: 40px;
  padding-right: 10px;
}
.sidebar .expended-view .item .menu .MuiButtonBase-root .MuiAccordionSummary-content .title-head {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 180px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: rgba(33, 33, 33, 0.6);
  cursor: pointer;
}
.sidebar .expended-view .item .menu .MuiButtonBase-root .MuiAccordionSummary-content .title-head .icons {
  width: 24px;
  height: 24px;
  color: rgba(33, 33, 33, 0.6);
}
.sidebar .expended-view .item .menu .MuiButtonBase-root:hover {
  background: rgba(0, 52, 187, 0.1);
}
.sidebar .expended-view .item .menu .MuiButtonBase-root:hover .MuiAccordionSummary-content .title-head {
  color: #0034bb;
}
.sidebar .expended-view .item .menu .MuiButtonBase-root:hover .MuiAccordionSummary-content .title-head .icons {
  color: #0034bb;
}
.sidebar .expended-view .item .menu .Mui-expanded {
  color: #0034bb;
  align-items: center;
  gap: 20px;
}
.sidebar .expended-view .item .menu .Mui-expanded .MuiAccordionSummary-content .title-head {
  display: flex;
  align-items: center;
  gap: 15px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: #0034bb;
  cursor: pointer;
}
.sidebar .expended-view .item .menu .Mui-expanded .MuiAccordionSummary-content .title-head .icons {
  width: 24px;
  height: 24px;
  color: #0034bb;
}
.sidebar .expended-view .item .menu:before {
  height: 0px !important;
}
.sidebar .expended-view::-webkit-scrollbar {
  width: 0px;
}
.sidebar.collapse {
  width: 60px;
  padding: 0px;
}
.sidebar.collapse .collapse-view {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 23px;
  padding-top: 27px;
  height: calc(100vh - 54px);
  overflow-y: scroll;
}
.sidebar.collapse .collapse-view .active {
  border-right: 4px solid #0034bb !important;
  background: rgba(0, 52, 187, 0.1) !important;
}
.sidebar.collapse .collapse-view .active .icons {
  margin-left: 4px;
  color: #0034bb !important;
}
.sidebar.collapse .collapse-view p {
  width: 100%;
  padding: 8px;
  padding-bottom: 6px;
  text-align: center;
}
.sidebar.collapse .collapse-view p .icons {
  width: 24px;
  height: 24px;
  color: rgba(33, 33, 33, 0.6);
  cursor: pointer;
}
.sidebar.collapse .collapse-view p:hover {
  width: 100%;
  background: rgba(0, 52, 187, 0.1);
}
.sidebar.collapse .collapse-view p:hover .icons {
  color: #0034bb !important;
}
.sidebar.collapse .collapse-view .collapse-list .button-icon {
  border-radius: 0px;
  margin: 0px;
  width: 100%;
  height: 44px;
}
.sidebar.collapse .collapse-view .collapse-list .button-icon .icons {
  width: 24px;
  height: 24px;
  color: rgba(33, 33, 33, 0.6);
}
.sidebar.collapse .collapse-view .collapse-list .button-icon:hover {
  border-right: 4px solid #0034bb;
  background: rgba(0, 52, 187, 0.1);
}
.sidebar.collapse .collapse-view .collapse-list .button-icon:hover .icons {
  margin-left: 4px;
}

.collapse-list-menu {
  margin-top: -30px;
}
.collapse-list-menu .MuiPaper-root {
  min-width: 180px;
}
.collapse-list-menu .MuiPaper-root .MuiList-root {
  margin: 2px 10px;
}
.collapse-list-menu .MuiPaper-root .MuiList-root .MuiButtonBase-root {
  padding: 10px 15px;
  font-size: 16px !important;
  font-weight: 500;
  color: #212121;
  background-color: #ffffff;
  margin-bottom: 5px;
}
.collapse-list-menu .MuiPaper-root .MuiList-root .MuiButtonBase-root:hover {
  border-radius: 10px;
  background: rgba(0, 52, 187, 0.05);
}
.collapse-list-menu .MuiPaper-root .MuiList-root .active {
  border-radius: 10px;
  background: rgba(0, 52, 187, 0.05);
}

.snackbar-cross {
  color: rgba(33, 33, 33, 0.8) !important;
  margin-right: -10px !important;
  margin-top: -49px;
  width: 19px;
  height: 19px;
  cursor: pointer;
}
.snackbar-cross:hover {
  background-color: rgba(33, 33, 33, 0.19);
  border-radius: 50px;
}

.product-menu .MuiPaper-root {
  padding: 24px 20px !important;
  border-radius: 12px !important;
  min-width: -moz-max-content;
  min-width: max-content;
  box-shadow: 0px 7px 30px 7px rgba(33, 33, 33, 0.03) !important;
}
.product-menu .MuiPaper-root .MuiList-root {
  padding: 0 !important;
}
.product-menu .MuiPaper-root h5 {
  font-size: 20px;
  color: #212121;
  font-weight: 600;
  line-height: 1;
}
.product-menu .MuiPaper-root .products-wrapper {
  margin-top: 12px;
}
.product-menu .MuiPaper-root .products-wrapper .product {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: all 400ms ease-in;
}
.product-menu .MuiPaper-root .products-wrapper .product:hover .icon-wrapper .icon {
  transform: scale(1.1);
}
.product-menu .MuiPaper-root .products-wrapper .product:last-child {
  margin-bottom: 0;
}
.product-menu .MuiPaper-root .products-wrapper .product .icon-wrapper {
  border-radius: 50%;
  background: rgba(33, 33, 33, 0.06);
  padding: 12px;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.product-menu .MuiPaper-root .products-wrapper .product .selected {
  color: #0034bb;
  background: rgba(0, 52, 187, 0.11);
  cursor: default;
}
.product-menu .MuiPaper-root .products-wrapper .product .text-wrapper .name {
  font-size: 16px;
  color: #212121;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 1.5;
}
.product-menu .MuiPaper-root .products-wrapper .product .text-wrapper .selected-name {
  font-weight: 600;
}
.product-menu .MuiPaper-root .products-wrapper .product .text-wrapper .desc {
  font-size: 14px;
  color: rgba(33, 33, 33, 0.8);
  line-height: 20px;
}

.signout-menu .MuiPaper-root {
  padding: 0 !important;
  border-radius: 6px !important;
  min-width: -moz-max-content;
  min-width: max-content;
  box-shadow: 0px 7px 30px 7px rgba(33, 33, 33, 0.03) !important;
}
.signout-menu .MuiPaper-root .MuiList-padding {
  padding: 0 !important;
}
.signout-menu .MuiPaper-root .user-menu .user {
  display: flex;
  align-items: center;
  -moz-column-gap: 11px;
       column-gap: 11px;
  padding: 12px 16px;
  margin-left: 0;
}
.signout-menu .MuiPaper-root .user-menu .user .right {
  text-align: left;
}
.signout-menu .MuiPaper-root .user-menu .user .right .name {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  line-height: 20px;
}
.signout-menu .MuiPaper-root .user-menu .user .right .email {
  font-size: 14px;
  color: #212121;
  line-height: 20px;
}
.signout-menu .MuiPaper-root .user-menu .divider {
  height: 2px;
  background: rgba(33, 33, 33, 0.1);
  cursor: default;
}
.signout-menu .MuiPaper-root .user-menu .btn-wrapper {
  text-align: left;
}
.signout-menu .MuiPaper-root .user-menu .btn-wrapper:hover {
  border-radius: 6px;
  background: rgba(0, 52, 187, 0.03);
}
.signout-menu .MuiPaper-root .user-menu .btn-wrapper .signout-btn {
  margin: 10px 16px !important;
  color: #212121;
  font-size: 14px;
  line-height: 20px;
  padding: 0 !important;
}
.signout-menu .MuiPaper-root .user-menu .btn-wrapper .signout-btn:hover {
  background: transparent !important;
}

@media only screen and (max-width: 767px) {
  .layout-one {
    height: 100lvh;
  }
  .layout-one .header {
    padding: 8px 16px;
    height: 41px;
  }
  .layout-one .header .left {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .layout-one .header .left .mob-menu {
    display: block;
    height: 24px;
    width: 24px;
    color: rgba(33, 33, 33, 0.6);
  }
  .layout-one .header .left img {
    max-width: 81px;
    display: block;
    margin: auto;
  }
  .layout-one .header .right ul {
    gap: 18px;
  }
  .layout-one .header .right ul li {
    color: #212121;
  }
  .layout-one .header .right ul li .icon {
    width: 18px;
    height: 18px;
  }
  .layout-one .header .right ul li .MuiIconButton-root {
    padding: 0;
  }
  .layout-one .header .right ul li .MuiIconButton-root .avatar {
    width: 18px;
    height: 18px;
  }
  .layout-one .page {
    display: flex;
    align-items: center;
    background: #fdfdfd;
    gap: 30px;
    background: #fdfdfd;
  }
  .layout-one .page .left {
    display: none;
  }
  .layout-one .page .right {
    height: calc(100vh - 54px);
    width: 100%;
    padding-inline: 16px;
    overflow: auto;
  }
  .mob-menu .MuiPaper-root {
    border-radius: 0;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: -7px 7px 40px 0px rgba(33, 33, 33, 0.2);
    border: none;
  }
  .mob-menu .MuiPaper-root .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 16px;
    margin-top: 18px;
  }
  .mob-menu .MuiPaper-root .head img {
    max-width: 100px;
  }
  .mob-menu .MuiPaper-root .user {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-inline: 16px;
    margin-top: 30px;
  }
  .mob-menu .MuiPaper-root .user .avatar {
    height: 18px;
    width: 18px;
  }
  .mob-menu .MuiPaper-root .user .account {
    font-size: 14px;
    font-weight: 500;
    color: #212121;
  }
  .mob-menu .MuiPaper-root .list {
    margin-top: 20px;
  }
  .mob-menu .MuiPaper-root .list .top-head {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-left: 16px;
    height: 44px;
    width: 230px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    color: rgba(33, 33, 33, 0.6);
    cursor: pointer;
  }
  .mob-menu .MuiPaper-root .list .top-head .icons {
    width: 18px;
    height: 18px;
    color: rgba(33, 33, 33, 0.6);
  }
  .mob-menu .MuiPaper-root .list .top-head a {
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    color: rgba(33, 33, 33, 0.6);
    cursor: pointer;
  }
  .mob-menu .MuiPaper-root .list .active {
    border-right: 4px solid #0034bb;
    background: rgba(0, 52, 187, 0.1);
    color: #0034bb;
  }
  .mob-menu .MuiPaper-root .list .active .icons {
    color: #0034bb;
  }
  .mob-menu .MuiPaper-root .list .active a {
    color: #0034bb;
  }
  .mob-menu .MuiPaper-root .signout-wrapper {
    display: flex;
    align-items: center;
    padding: 9px 16px;
    gap: 16px;
  }
  .mob-menu .MuiPaper-root .signout-wrapper .signout-icon {
    height: 18px;
    width: 18px;
    color: rgba(33, 33, 33, 0.6);
    stroke-width: 2;
  }
  .mob-menu .MuiPaper-root .signout-wrapper .signout-text {
    font-size: 14px;
    font-weight: 500;
    color: rgba(33, 33, 33, 0.6);
  }
}
button {
  text-transform: unset !important;
}

.btn-outlined,
.btn-contained {
  font-size: 16px !important;
  border-radius: 8px !important;
}

.btn-contained:disabled {
  background: #0034bb !important;
  color: #ffffff !important;
  opacity: 0.5 !important;
}

.btn-text {
  font-size: 14px !important;
  min-width: unset !important;
  min-height: unset !important;
  text-transform: unset !important;
  padding: 0 !important;
  line-height: 1.5 !important;
  border-radius: 0 !important;
}
.btn-text:hover {
  background-color: transparent !important;
}

.ecn-input {
  width: 100%;
}
.ecn-input .MuiInputBase-root {
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #212121;
}
.ecn-input .MuiInputBase-root .MuiInputBase-input {
  padding: 10px 16px;
}
.ecn-input .MuiInputBase-root .MuiInputBase-input::-moz-placeholder {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
}
.ecn-input .MuiInputBase-root .MuiInputBase-input::placeholder {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
}
.ecn-input .MuiInputBase-root:focus, .ecn-input .MuiInputBase-root:active {
  outline: none;
}
.ecn-input .MuiFormHelperText-root {
  margin-left: 2px;
}

.ecn-underline-input {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  line-height: 1.5;
}
.ecn-underline-input .MuiInputLabel-root {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
}
.ecn-underline-input .MuiInput-input {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
  font-weight: 500;
}
.ecn-underline-input::-moz-placeholder {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.3);
  font-weight: 400;
}
.ecn-underline-input::placeholder {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.3);
  font-weight: 400;
}
.ecn-underline-input:focus, .ecn-underline-input:active {
  outline: none;
}

.ecn-underline-input-error .MuiInputLabel-root {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6) !important;
  font-weight: 400;
}

.ecn-pagination .MuiPagination-ul {
  flex-wrap: nowrap;
}
.ecn-pagination .MuiPagination-ul li .Mui-selected {
  background: rgba(0, 52, 187, 0.05);
  color: #0034bb;
}
.ecn-pagination .MuiPagination-ul li:first-child {
  flex-basis: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ecn-pagination .MuiPagination-ul li:first-child .MuiButtonBase-root {
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 8px 14px;
}
.ecn-pagination .MuiPagination-ul li:first-child > button::after {
  margin-left: 10px;
  content: "Previous";
}
.ecn-pagination .MuiPagination-ul li:last-child {
  flex-basis: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ecn-pagination .MuiPagination-ul li:last-child .MuiButtonBase-root {
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 8px 0px;
  display: unset;
}
.ecn-pagination .MuiPagination-ul li:last-child > button::before {
  margin-right: 10px;
  content: "Next";
}

.ecn-breadcrumb {
  margin-top: 40px !important;
  margin-bottom: 25px !important;
}
.ecn-breadcrumb .MuiBreadcrumbs-ol .MuiBreadcrumbs-li {
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 33, 33, 0.6);
  cursor: pointer;
}
.ecn-breadcrumb .MuiBreadcrumbs-ol .MuiBreadcrumbs-li:last-child {
  color: #212121;
  cursor: default;
}
.ecn-breadcrumb .MuiBreadcrumbs-ol .MuiBreadcrumbs-separator {
  margin-left: 7px;
  margin-right: 7px;
}

.ecn-chip {
  border-radius: 8px !important;
  padding: 4px 10px !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
}

.ecn-checkbox {
  stroke-width: 0.3px !important;
}

.ecn-select {
  width: 100%;
  border-radius: 8px !important;
  font-weight: 500 !important;
  color: #212121 !important;
}
.ecn-select .MuiSelect-select {
  padding: 10px 16px;
}

.ecn-select-empty {
  color: rgba(0, 0, 0, 0.3) !important;
}
.ecn-select-empty .MuiSelect-select {
  font-size: 14px !important;
}

.MuiMenu-root .MuiPaper-root {
  margin-top: 5px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.ecn-popper {
  background: #ffffff !important;
  border-radius: 8px !important;
  box-shadow: 0px 12px 40px 0px rgba(33, 33, 33, 0.05) !important;
  border: 1px solid rgba(33, 33, 33, 0.1) !important;
  padding: 16px 0 !important;
  max-width: 400px !important;
  width: 100% !important;
}
.ecn-popper .ecn-popper-item {
  padding: 0 16px !important;
  font-size: 14px !important;
  color: #212121 !important;
  line-height: 24px !important;
}
.ecn-popper .ecn-popper-item:hover {
  background: rgba(33, 33, 33, 0.1) !important;
}

.add-file {
  min-height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-file .wrapper .error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 124px;
  margin-bottom: 124px;
}
.add-file .wrapper .error img {
  width: 325px;
  height: 325px;
  border-radius: 167px;
  border: 8px solid rgba(33, 33, 33, 0.6);
  margin-bottom: 40px;
}
.add-file .wrapper .error h3 {
  color: #ba0000;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
}
.add-file .wrapper .error .btn-oulined {
  border-radius: 9px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  color: #212121;
  min-width: 190px;
  padding: 6px 19px;
  font-size: 18px;
  font-weight: 500;
}
.add-file .wrapper .progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.add-file .wrapper .progress .image-loader {
  width: 325px;
  height: 325px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-file .wrapper .progress .image-loader img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 8px solid rgba(33, 33, 33, 0.6);
}
.add-file .wrapper .progress .image-loader .circular-progress {
  color: #0034bb;
  position: absolute;
}
.add-file .wrapper .progress h3 {
  color: #212121;
  font-size: 24px;
  font-weight: 600;
}
.add-file .wrapper .progress .in-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.add-file .wrapper .progress .in-progress p {
  color: #212121;
  font-size: 18px;
  font-weight: 400;
}
.add-file .wrapper .progress .in-progress .icon {
  width: 34px;
  height: 34px;
  color: #0034bb;
}
.add-file .wrapper .filename {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
}
.add-file .wrapper .filename .container {
  border-radius: 9px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 40px 97px 41px 98px;
}
.add-file .wrapper .filename .container .cropper {
  position: relative;
  height: 310px;
  width: 310px;
  background: linear-gradient(to right, #a6a6a6 5px, transparent 5px) 0 0, linear-gradient(to right, #a6a6a6 5px, transparent 5px) 0 100%, linear-gradient(to left, #a6a6a6 5px, transparent 5px) 100% 0, linear-gradient(to left, #a6a6a6 5px, transparent 5px) 100% 100%, linear-gradient(to bottom, #a6a6a6 5px, transparent 5px) 0 0, linear-gradient(to bottom, #a6a6a6 5px, transparent 5px) 100% 0, linear-gradient(to top, #a6a6a6 5px, transparent 5px) 0 100%, linear-gradient(to top, #a6a6a6 5px, transparent 5px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 50px 50px;
}
.add-file .wrapper .filename .container .zoom-buttons {
  gap: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 43px;
}
.add-file .wrapper .filename .container .zoom-buttons .btn-text {
  color: #212121;
  font-size: 18px;
  font-weight: 500;
}
.add-file .wrapper .filename .container .zoom-buttons .btn-text .icon {
  width: 24px;
  height: 24px;
}
.add-file .wrapper .filename .buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;
  gap: 35px;
}
.add-file .wrapper .filename .buttons .btn-oulined {
  gap: 10px;
  border-radius: 9px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  color: #212121;
  min-width: 190px;
  padding: 5px 1px;
  font-size: 18px;
  font-weight: 500;
}
.add-file .wrapper .filename .buttons .btn-oulined .icon {
  width: 20px;
  height: 20px;
}
.add-file .wrapper .filename .buttons .btn-contained {
  border-radius: 9px;
  padding: 8px 19px;
  border: 1px solid #0034bb;
  background: #0034bb;
  color: #ffffff;
  min-width: 363px;
}
.add-file .wrapper .filename .buttons .save-changes {
  font-size: 18px;
  font-weight: 500;
  padding: 5px 1px;
  min-width: 190px;
  border-radius: 9px;
  background: #0034bb;
  border: 1px solid #0034bb;
  color: #ffffff;
}

.wrapper-upload {
  width: 100%;
  background-image: repeating-linear-gradient(4deg, rgba(33, 33, 33, 0.2), rgba(33, 33, 33, 0.2) 13px, transparent 13px, transparent 26px, rgba(33, 33, 33, 0.2) 26px), repeating-linear-gradient(94deg, rgba(33, 33, 33, 0.2), rgba(33, 33, 33, 0.2) 13px, transparent 13px, transparent 26px, rgba(33, 33, 33, 0.2) 26px), repeating-linear-gradient(184deg, rgba(33, 33, 33, 0.2), rgba(33, 33, 33, 0.2) 13px, transparent 13px, transparent 26px, rgba(33, 33, 33, 0.2) 26px), repeating-linear-gradient(274deg, rgba(33, 33, 33, 0.2), rgba(33, 33, 33, 0.2) 13px, transparent 13px, transparent 26px, rgba(33, 33, 33, 0.2) 26px);
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}
.wrapper-upload .centeral-part {
  text-align: center;
  margin: auto;
  padding-top: 43px;
  padding-bottom: 48px;
}
.wrapper-upload .centeral-part .button button {
  background: transparent;
  outline: none;
  padding: 8px 25px;
  color: rgba(33, 33, 33, 0.6);
  border-radius: 5px;
  font-weight: 600;
  border: none;
}
.wrapper-upload .centeral-part .button button .icon {
  min-height: 65px;
  min-width: 65px;
}
.wrapper-upload .centeral-part .text .sub-text {
  color: #212121;
  font-size: 16px;
  font-weight: 500;
  margin-top: 23px;
  margin-bottom: 14px;
}
.wrapper-upload .centeral-part .text .sub-text-2 {
  color: rgba(33, 33, 33, 0.4);
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 13px;
}
.wrapper-upload .centeral-part .btn-outlined {
  gap: 10px;
  border-radius: 9px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 7px 1px;
  color: #212121;
  min-width: 246px;
  font-size: 18px;
  font-weight: 500;
}
.wrapper-upload .centeral-part .btn-outlined icon {
  color: #212121;
  min-width: 20px;
}
.wrapper-upload .centeral-part .btn-outlined:hover {
  background: rgba(0, 52, 187, 0.1);
}

@media only screen and (min-width: 1000px) and (max-width: 1330px) {
  .upload .MuiPaper-root .heading {
    padding-top: 27px;
    padding-bottom: 30px;
  }
  .upload .MuiPaper-root .container h1 {
    margin-bottom: 27px;
  }
  .upload .filename .container {
    padding: 33px 81px 34px 82px !important;
  }
  .upload .filename .container .zoom-buttons {
    margin-top: 36px !important;
  }
}
.profile .user-card {
  max-width: 692px;
  margin-inline: auto;
  border-radius: 10px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 30px 25px;
  margin-top: 25px;
  position: relative;
}
.profile .user-card .edit-btn {
  position: absolute;
  top: 30px;
  right: 25px;
}
.profile .user-card .img-wrapper {
  text-align: center;
}
.profile .user-card .img-wrapper img {
  height: 175px;
  width: 175px;
  border-radius: 50%;
}
.profile .user-card .name {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #212121;
  margin-top: 16px;
}
.profile .user-card .designation {
  text-align: center;
  margin-top: 4px;
  color: #212121;
  margin-bottom: 5px;
}
.profile .user-card .all-fields .user-field {
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding-bottom: 13px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
}
.profile .user-card .all-fields .user-field:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.profile .user-card .all-fields .user-field .field-icon {
  height: 18px;
  width: 18px;
  color: rgba(33, 33, 33, 0.6);
  margin-top: 2px;
  stroke-width: 3;
}
.profile .user-card .all-fields .user-field .details .label {
  font-size: 14px;
  color: #212121;
}
.profile .user-card .all-fields .user-field .details .data {
  color: #212121;
  font-weight: 600;
  margin-top: 6px;
}
.profile .user-card .mob-edit-btn {
  display: none;
}
.profile .edit_user-card {
  max-width: 692px;
  margin-inline: auto;
  border-radius: 10px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 30px 25px;
  margin-top: 25px;
}
.profile .edit_user-card .img-wrapper {
  margin-inline: auto;
  width: -moz-max-content;
  width: max-content;
  margin-bottom: 40px;
  position: relative;
}
.profile .edit_user-card .img-wrapper img {
  height: 175px;
  width: 175px;
  border-radius: 50%;
}
.profile .edit_user-card .img-wrapper .edit-img {
  position: absolute;
  right: 20px;
  bottom: 20px;
  padding: 13px;
  height: 44px;
  width: 44px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  border-radius: 50%;
  background: #ffffff;
  color: #0034bb;
  cursor: pointer;
}
.profile .edit_user-card .text-fields .row {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
.profile .edit_user-card .text-fields .row > .field {
  flex: 1 1 45%;
}
.profile .edit_user-card .text-fields .field {
  margin-bottom: 16px;
}
.profile .edit_user-card .text-fields .field p {
  font-size: 14px;
  line-height: 171%;
  margin-bottom: 2px;
  color: #212121;
}
.profile .edit_user-card .text-fields .field .mobile-input {
  font-size: 16px;
  font-weight: 500;
  height: auto;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.26);
  padding: 0px 3px;
}
.profile .edit_user-card .text-fields .field .mobile-input input {
  font-size: 16px;
  font-weight: 500;
  font-family: Poppins;
  color: rgba(0, 0, 0, 0.38);
}
.profile .edit_user-card .text-fields .field .mobile-input .form-control {
  width: 91%;
  border-radius: 8px;
  margin-left: 26px;
  border: unset;
  border-left: none;
  height: 41px;
}
.profile .edit_user-card .text-fields .field .mobile-input .flag-dropdown {
  border-radius: 8px 0 0 8px;
  border-right: none;
  border: unset;
  background: rgba(255, 255, 255, 0.8);
}
.profile .edit_user-card .text-fields .field .mobile-input .flag-dropdown .selected-flag {
  padding: 0 0 0 19px;
}
.profile .edit_user-card .text-fields .field .mobile-input .flag-dropdown .selected-flag .flag .arrow {
  border-top: unset;
  border-left: unset;
  border-right: unset;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M4 6L8 10L12 6" stroke="%23212121" stroke-opacity="0.8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  width: 16px;
  height: 16px;
  background-position: center;
  background-repeat: no-repeat;
  top: unset;
  left: 22px;
}
.profile .edit_user-card .btn-group {
  margin-top: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile .edit_user-card .btn-group button {
  box-shadow: none;
  min-width: 160px;
  border-radius: 8px;
  padding: 10px 19px;
  font-size: 16px;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .profile .user-card {
    max-width: 692px;
    border: none;
    padding: 0;
    margin-top: 25px;
  }
  .profile .user-card .edit-btn {
    display: none;
  }
  .profile .user-card .img-wrapper img {
    height: 100px;
    width: 100px;
  }
  .profile .user-card .name {
    font-size: 16px;
    margin-top: 6px;
  }
  .profile .user-card .designation {
    font-size: 14px;
    margin-top: 2px;
    margin-bottom: 3px;
  }
  .profile .user-card .all-fields .user-field {
    margin-top: 25px;
    padding-bottom: 21px;
    padding-inline: 16px;
  }
  .profile .user-card .all-fields .user-field:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
  .profile .user-card .all-fields .user-field .details .label {
    font-size: 12px;
  }
  .profile .user-card .all-fields .user-field .details .data {
    font-size: 14px;
    margin-top: 4px;
  }
  .profile .user-card .mob-edit-btn {
    display: flex;
    margin-top: 39px;
    width: 100%;
    border: 1.5px solid #0034bb;
    border-radius: 7px;
    padding: 10px 8px;
  }
  .profile .edit_user-card {
    border: none;
    padding: 0;
  }
  .profile .edit_user-card .img-wrapper img {
    height: 100px;
    width: 100px;
  }
  .profile .edit_user-card .img-wrapper .edit-img {
    right: 2px;
    bottom: 2px;
    padding: 8px;
    height: 35px;
    width: 35px;
  }
  .profile .edit_user-card .text-fields .row {
    flex-direction: column;
    gap: 0;
  }
  .profile .edit_user-card .text-fields .row > .field {
    flex: 1 1 100%;
  }
  .profile .edit_user-card .text-fields .field {
    margin-bottom: 16px;
    width: 100%;
  }
  .profile .edit_user-card .text-fields .field p {
    font-size: 14px;
    line-height: 171%;
    margin-bottom: 2px;
    color: #212121;
  }
  .profile .edit_user-card .text-fields .field .mobile-input {
    font-size: 16px;
    font-weight: 500;
    height: auto;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.26);
    padding: 0px 3px;
  }
  .profile .edit_user-card .text-fields .field .mobile-input input {
    font-size: 16px;
    font-weight: 500;
    font-family: Poppins;
    color: rgba(0, 0, 0, 0.38);
  }
  .profile .edit_user-card .text-fields .field .mobile-input .form-control {
    width: 91%;
    border-radius: 8px;
    margin-left: 26px;
    border: unset;
    border-left: none;
    height: 41px;
  }
  .profile .edit_user-card .text-fields .field .mobile-input .flag-dropdown {
    border-radius: 8px 0 0 8px;
    border-right: none;
    border: unset;
    background: rgba(255, 255, 255, 0.8);
  }
  .profile .edit_user-card .text-fields .field .mobile-input .flag-dropdown .selected-flag {
    padding: 0 0 0 19px;
  }
  .profile .edit_user-card .text-fields .field .mobile-input .flag-dropdown .selected-flag .flag .arrow {
    border-top: unset;
    border-left: unset;
    border-right: unset;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M4 6L8 10L12 6" stroke="%23212121" stroke-opacity="0.8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    width: 16px;
    height: 16px;
    background-position: center;
    background-repeat: no-repeat;
    top: unset;
    left: 22px;
  }
  .profile .edit_user-card .btn-group {
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .profile .edit_user-card .btn-group button {
    box-shadow: none;
    min-width: 160px;
    border-radius: 8px;
    padding: 10px 19px;
    font-size: 16px;
    font-weight: 500;
  }
}
.security h5 {
  font-size: 20px;
  font-weight: 600;
  color: #212121;
  margin-top: 40px;
  margin-bottom: 25px;
}
.security .setting-list {
  padding: 30px 25px;
  border-radius: 10px;
  border: 1px solid rgba(33, 33, 33, 0.2);
}
.security .setting-list .container .option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;
  padding-top: 25px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  cursor: pointer;
}
.security .setting-list .container .option:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
.security .setting-list .container .option:first-child {
  padding-top: 0;
}
.security .setting-list .container .option .name {
  font-weight: 600;
  color: #212121;
}

.session-drawer .MuiPaper-root {
  border-radius: 0;
  padding: 36px 42px;
  min-width: 924px;
  justify-content: flex-start;
  background-color: #ffffff;
  box-shadow: -17px 4px 30px 0px rgba(33, 33, 33, 0.2);
  border: none;
}
.session-drawer .MuiPaper-root .session-view .back-btn {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
}
.session-drawer .MuiPaper-root .session-view .back-btn .MuiButton-startIcon {
  margin-right: 16px;
}
.session-drawer .MuiPaper-root .session-view .session-list {
  margin-top: 30px;
}
.session-drawer .MuiPaper-root .session-view .session-list .session {
  padding: 0 25px 26px 25px;
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.session-drawer .MuiPaper-root .session-view .session-list .session .body {
  display: flex;
  align-items: flex-start;
  gap: 18px;
}
.session-drawer .MuiPaper-root .session-view .session-list .session .body .details .name {
  font-weight: 600;
  color: #212121;
  line-height: 145%;
}
.session-drawer .MuiPaper-root .session-view .session-list .session .body .details .sub {
  display: flex;
  align-items: center;
  margin-top: 9px;
}
.session-drawer .MuiPaper-root .session-view .session-list .session .body .details .sub .location,
.session-drawer .MuiPaper-root .session-view .session-list .session .body .details .sub .time,
.session-drawer .MuiPaper-root .session-view .session-list .session .body .details .sub .active {
  color: #212121;
  line-height: 145%;
}
.session-drawer .MuiPaper-root .session-view .session-list .session .body .details .sub .circle {
  margin-left: 23px;
  margin-right: 12px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: rgba(33, 33, 33, 0.2);
}
.session-drawer .MuiPaper-root .session-view .session-list .session .logout-btn {
  border-radius: 7px;
  border-color: rgba(33, 33, 33, 0.2);
  font-size: 14px;
  font-weight: 500;
  color: #212121;
  padding: 8px 18px;
}

.password-drawer .MuiPaper-root {
  border-radius: 0;
  padding: 36px 42px;
  min-width: 924px;
  justify-content: flex-start;
  background-color: #ffffff;
  box-shadow: -17px 4px 30px 0px rgba(33, 33, 33, 0.2);
  border: none;
}
.password-drawer .MuiPaper-root .password-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.password-drawer .MuiPaper-root .password-view .container .back-btn {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
}
.password-drawer .MuiPaper-root .password-view .container .back-btn .MuiButton-startIcon {
  margin-right: 16px;
}
.password-drawer .MuiPaper-root .password-view .container .all-fields {
  max-width: 400px;
  margin-top: 32px;
}
.password-drawer .MuiPaper-root .password-view .container .all-fields .field {
  margin-bottom: 25px;
}
.password-drawer .MuiPaper-root .password-view .container .all-fields .field:nth-child(2) {
  margin-bottom: 25px;
}
.password-drawer .MuiPaper-root .password-view .container .all-fields .field label {
  font-size: 14px;
  color: #212121;
  line-height: 171%;
}
.password-drawer .MuiPaper-root .password-view .container .all-fields .field .error-password {
  color: #ba0000;
  font-size: 14px;
}
.password-drawer .MuiPaper-root .password-view .container .all-fields .mob-support {
  display: none;
}
.password-drawer .MuiPaper-root .password-view .update-button {
  width: 195px;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 52px;
  border-radius: 7px;
  box-shadow: none;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 29px;
}
.password-drawer .MuiPaper-root .password-view .update-button:disabled {
  background: rgba(0, 52, 187, 0.6);
  color: rgba(255, 255, 255, 0.6);
}

.email-drawer .MuiPaper-root {
  border-radius: 0;
  padding: 36px 42px;
  min-width: 924px;
  justify-content: flex-start;
  background-color: #ffffff;
  box-shadow: -17px 4px 30px 0px rgba(33, 33, 33, 0.2);
  border: none;
}
.email-drawer .MuiPaper-root .email-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.email-drawer .MuiPaper-root .email-view .container .back-btn {
  font-size: 18px;
  font-weight: 600;
  color: #212121;
}
.email-drawer .MuiPaper-root .email-view .container .back-btn .MuiButton-startIcon {
  margin-right: 16px;
}
.email-drawer .MuiPaper-root .email-view .container .all-emails {
  margin-top: 30px;
  padding: 34px 25px;
}
.email-drawer .MuiPaper-root .email-view .container .all-emails .email {
  margin-top: 25px;
  padding-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
}
.email-drawer .MuiPaper-root .email-view .container .all-emails .email:first-child {
  margin-top: 0;
}
.email-drawer .MuiPaper-root .email-view .container .all-emails .email .id {
  font-weight: 600;
  color: #212121;
}
.email-drawer .MuiPaper-root .email-view .container .all-emails .email .primary-chip {
  border-radius: 10px;
  background: rgba(2, 122, 72, 0.05);
  padding: 4px 10px;
  font-size: 14px;
  line-height: 140%;
  color: #027a48;
}
.email-drawer .MuiPaper-root .email-view .container .all-emails .note {
  border-radius: 10px;
  background: rgba(0, 52, 187, 0.05);
  padding: 10px;
  font-size: 14px;
  color: #212121;
  margin-top: 23px;
}
.email-drawer .MuiPaper-root .email-view .container .all-emails .note span {
  font-weight: 600;
}
.email-drawer .MuiPaper-root .email-view .container .all-emails .add-btn {
  margin-top: 26px;
  border-radius: 7px;
  padding: 9px 25px;
  font-size: 16px;
  font-weight: 500;
  color: #212121;
  border-color: rgba(33, 33, 33, 0.2);
}
.email-drawer .MuiPaper-root .email-view .container .all-emails .email-edit {
  border: 1px solid rgba(33, 33, 33, 0.2);
  border-radius: 12px;
  padding: 25px;
  margin-top: 26px;
}
.email-drawer .MuiPaper-root .email-view .container .all-emails .email-edit .wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.email-drawer .MuiPaper-root .email-view .container .all-emails .email-edit .wrapper .field label {
  font-size: 14px;
  color: #212121;
  line-height: 171%;
  margin-bottom: 2px;
}
.email-drawer .MuiPaper-root .email-view .container .all-emails .email-edit .verify-btn {
  border-radius: 7px;
  padding: 9px 19px;
  font-size: 16px;
  font-weight: 500;
  min-width: 172px;
  margin-top: 30px;
}
.email-drawer .MuiPaper-root .email-view .container .all-emails .email-edit .verify-btn:disabled {
  background: rgba(0, 52, 187, 0.6);
  color: rgba(255, 255, 255, 0.6);
}
.email-drawer .MuiPaper-root .email-view .container .all-emails .email-edit .otp-wrapper .otp-label {
  font-weight: 600;
  color: #212121;
  margin-top: 28px;
  margin-bottom: 12px;
}
.email-drawer .MuiPaper-root .email-view .container .all-emails .email-edit .otp-wrapper .otp-container {
  -moz-column-gap: 12px;
       column-gap: 12px;
}
.email-drawer .MuiPaper-root .email-view .container .all-emails .email-edit .otp-wrapper .otp-container .otp-input {
  height: 48px;
  width: 48px !important;
  border-radius: 7px;
  border: 0;
  background: #f3f4f6;
}
.email-drawer .MuiPaper-root .email-view .container .all-emails .email-edit .otp-wrapper .otp-container .otp-input:focus, .email-drawer .MuiPaper-root .email-view .container .all-emails .email-edit .otp-wrapper .otp-container .otp-input:focus-within, .email-drawer .MuiPaper-root .email-view .container .all-emails .email-edit .otp-wrapper .otp-container .otp-input:focus-visible {
  border: 0;
  outline: none;
}
.email-drawer .MuiPaper-root .email-view .container .all-emails .email-edit .otp-wrapper .resend {
  font-size: 14px;
  color: #212121;
  margin-top: 8px;
}
.email-drawer .MuiPaper-root .email-view .container .all-emails .email-edit .otp-wrapper .resend span {
  font-weight: 600;
  color: #0034bb;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .security h5 {
    display: none;
  }
  .security .setting-list {
    padding: 0;
    border-radius: 0;
    border: none;
    margin-top: 30px;
  }
  .security .setting-list .container .option {
    padding-bottom: 16px;
    padding-top: 25px;
  }
  .security .setting-list .container .option .name {
    font-size: 14px;
  }
  .security .setting-list .container .option .security-icon {
    height: 24px;
    width: 24px;
    color: rgba(33, 33, 33, 0.6);
  }
  .session-drawer .MuiPaper-root {
    padding: 0;
    min-width: 100vw;
    padding-bottom: 50px;
  }
  .session-drawer .MuiPaper-root .session-view .back-btn {
    font-size: 14px;
  }
  .session-drawer .MuiPaper-root .session-view .back-btn .MuiButton-startIcon {
    margin-right: 16px;
  }
  .session-drawer .MuiPaper-root .session-view .session-list {
    margin-top: 0px;
    border-top: 1px solid rgba(33, 33, 33, 0.2);
  }
  .session-drawer .MuiPaper-root .session-view .session-list .session {
    margin-inline: 16px;
    margin-top: 25px;
  }
  .session-drawer .MuiPaper-root .session-view .session-list .session:last-child {
    border-bottom: 0;
  }
  .session-drawer .MuiPaper-root .session-view .session-list .session .body {
    gap: 16px;
  }
  .session-drawer .MuiPaper-root .session-view .session-list .session .body .details .name {
    font-size: 14px;
  }
  .session-drawer .MuiPaper-root .session-view .session-list .session .body .details .sub {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 12px;
  }
  .session-drawer .MuiPaper-root .session-view .session-list .session .body .details .sub .location,
  .session-drawer .MuiPaper-root .session-view .session-list .session .body .details .sub .time,
  .session-drawer .MuiPaper-root .session-view .session-list .session .body .details .sub .active {
    font-size: 14px;
    margin-bottom: 12px;
  }
  .session-drawer .MuiPaper-root .session-view .session-list .session .body .details .sub .circle {
    display: none;
  }
  .password-drawer .MuiPaper-root {
    padding: 0;
    min-width: 100vw;
    padding-bottom: 50px;
  }
  .password-drawer .MuiPaper-root .password-view .container .back-btn {
    font-size: 14px;
  }
  .password-drawer .MuiPaper-root .password-view .container .all-fields {
    max-width: 100%;
    margin-top: 0px;
    border-top: 1px solid rgba(33, 33, 33, 0.2);
  }
  .password-drawer .MuiPaper-root .password-view .container .all-fields .field {
    margin-top: 25px;
    margin-inline: 16px;
  }
  .password-drawer .MuiPaper-root .password-view .container .all-fields .field label {
    font-size: 14px;
    color: #212121;
    line-height: 171%;
  }
  .password-drawer .MuiPaper-root .password-view .container .all-fields .field .error-password {
    color: #ba0000;
    font-size: 14px;
  }
  .password-drawer .MuiPaper-root .password-view .container .all-fields .mob-support {
    display: block;
    margin-inline: 16px;
    padding: 12px 20px;
    border-radius: 6px;
    border: 1px solid rgba(33, 33, 33, 0.2);
  }
  .password-drawer .MuiPaper-root .password-view .update-button {
    width: auto;
    margin-inline: 16px;
    margin-bottom: 40px;
  }
  #password-popover {
    display: none;
  }
  .email-drawer .MuiPaper-root {
    padding: 0;
    min-width: 100vw;
    width: 100vw;
    padding-bottom: 50px;
  }
  .email-drawer .MuiPaper-root .email-view .container .back-btn {
    font-size: 14px;
  }
  .email-drawer .MuiPaper-root .email-view .container .all-emails {
    margin-top: 0px;
    padding: 16px;
    border-top: 1px solid rgba(33, 33, 33, 0.2);
  }
  .email-drawer .MuiPaper-root .email-view .container .all-emails .email {
    margin-top: 25px;
    padding-bottom: 18px;
    border-bottom: 1px solid rgba(33, 33, 33, 0.2);
  }
  .email-drawer .MuiPaper-root .email-view .container .all-emails .email:first-child {
    flex-direction: column;
    align-items: flex-start;
  }
  .email-drawer .MuiPaper-root .email-view .container .all-emails .email .id {
    font-size: 14px;
  }
  .email-drawer .MuiPaper-root .email-view .container .all-emails .email .primary-chip {
    font-size: 12px;
    margin-top: 8px;
  }
  .email-drawer .MuiPaper-root .email-view .container .all-emails .note {
    font-size: 12px;
  }
  .email-drawer .MuiPaper-root .email-view .container .all-emails .add-btn {
    font-size: 14px;
  }
  .email-drawer .MuiPaper-root .email-view .container .all-emails .email-edit {
    padding: 12px;
    margin-top: 16px;
  }
  .email-drawer .MuiPaper-root .email-view .container .all-emails .email-edit .wrapper .field label {
    font-size: 12px;
  }
  .email-drawer .MuiPaper-root .email-view .container .all-emails .email-edit .verify-btn {
    font-size: 14px;
  }
  .email-drawer .MuiPaper-root .email-view .container .all-emails .email-edit .otp-wrapper .otp-label {
    margin-top: 18px;
    margin-bottom: 8px;
  }
  .email-drawer .MuiPaper-root .email-view .container .all-emails .email-edit .otp-wrapper .otp-container {
    -moz-column-gap: 8px;
         column-gap: 8px;
  }
  .email-drawer .MuiPaper-root .email-view .container .all-emails .email-edit .otp-wrapper .otp-container .otp-input {
    height: 36px;
    width: 36px !important;
    font-size: 16px;
  }
  .email-drawer .MuiPaper-root .email-view .container .all-emails .email-edit .otp-wrapper .resend {
    font-size: 12px;
  }
}
.help h5 {
  font-size: 20px;
  font-weight: 600;
  color: #212121;
  margin-top: 40px;
  margin-bottom: 25px;
}
.help .admin-info {
  border: 1px solid rgba(33, 33, 33, 0.2);
  border-radius: 10px;
  padding: 25px;
}
.help .admin-info .heading {
  color: #212121;
  font-weight: 600;
  margin-bottom: 9px;
}
.help .admin-info .field {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-top: 30px;
  padding-bottom: 13px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
}
.help .admin-info .field:last-child {
  border-bottom: 0;
}
.help .admin-info .field .field-icon {
  color: #0034bb;
  stroke-width: 2 !important;
  stroke-opacity: 0.7;
  margin-top: 3px;
}
.help .admin-info .field .details label {
  font-size: 14px;
  color: #212121;
}
.help .admin-info .field .details .data {
  display: block;
  color: #212121;
  font-weight: 600;
  text-decoration: none;
  margin-top: 6px;
}
.help .support-form {
  border: 1px solid rgba(33, 33, 33, 0.2);
  border-radius: 10px;
  padding: 30px;
}
.help .support-form .heading {
  font-size: 24px;
  font-weight: 600;
  color: #212121;
}
.help .support-form .sub-heading {
  margin-top: 4px;
  font-weight: 500;
  color: #212121;
}
.help .support-form .form {
  max-width: 90%;
}
.help .support-form .form .row {
  display: flex;
  align-items: flex-start;
  gap: 25px;
}
.help .support-form .form .field {
  margin-top: 25px;
  flex: 0.5;
}
.help .support-form .form .field label {
  font-size: 14px;
  line-height: 171%;
  color: #212121;
  margin-bottom: 2px;
}
.help .support-form .form .field .mobile-input {
  height: auto;
  border-radius: 8px;
  border: 1.4px solid rgba(33, 33, 33, 0.3);
  padding-right: 2px;
}
.help .support-form .form .field .mobile-input:hover {
  border: 1.4px solid #212121;
}
.help .support-form .form .field .mobile-input:focus, .help .support-form .form .field .mobile-input:focus-within {
  border: 1.4px solid #0034bb;
  outline: 1px solid #0034bb;
}
.help .support-form .form .field .mobile-input input {
  font-size: 16px;
  font-weight: 400;
  font-family: Poppins;
  color: #212121;
}
.help .support-form .form .field .mobile-input .form-control {
  width: 91%;
  border-radius: 8px;
  margin-left: 26px;
  border: unset;
  border-left: none;
  height: 41px;
}
.help .support-form .form .field .mobile-input .flag-dropdown {
  border-radius: 8px 0 0 8px;
  border-right: none;
  border: unset;
  background: rgba(255, 255, 255, 0.8);
}
.help .support-form .form .field .mobile-input .flag-dropdown .selected-flag {
  padding: 0 0 0 19px;
  background-color: unset;
}
.help .support-form .form .field .mobile-input .flag-dropdown .selected-flag .flag .arrow {
  border-top: unset;
  border-left: unset;
  border-right: unset;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M4 6L8 10L12 6" stroke="%23212121" stroke-opacity="0.8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  width: 16px;
  height: 16px;
  background-position: center;
  background-repeat: no-repeat;
  top: unset;
  left: 22px;
}
.help .support-form .form .field .mobile-error {
  font-size: 12px;
  color: #ba0000;
}
.help .support-form .form .field .textarea .MuiInputBase-root .MuiInputBase-input {
  padding: 0;
}
.help .support-form .form .btn-wrapper {
  text-align: right;
}
.help .support-form .form .btn-wrapper .submit-btn {
  width: 230px;
  border-radius: 9px;
  box-shadow: none;
  margin-top: 46px;
  padding: 8px 29px;
  font-size: 18px;
  font-weight: 500;
}
.help .support-form .form .btn-wrapper .submit-btn:disabled {
  background: #0034bb;
  color: #ffffff;
  opacity: 0.6;
}

@media only screen and (max-width: 767px) {
  .help h5 {
    display: none;
  }
  .help .admin-info {
    border: none;
    padding: 0;
    margin-top: 25px;
  }
  .help .support-form {
    border: none;
    padding: 0px;
    margin-top: 25px;
  }
  .help .support-form .heading {
    font-size: 16px;
  }
  .help .support-form .sub-heading {
    font-size: 12px;
  }
  .help .support-form .form {
    max-width: 100%;
  }
  .help .support-form .form .row {
    flex-direction: column;
    gap: 0px;
  }
  .help .support-form .form .field {
    margin-top: 16px;
    flex: 0.5;
    width: 100%;
  }
  .help .support-form .form .field label {
    font-size: 14px;
    line-height: 171%;
    color: #212121;
    margin-bottom: 2px;
  }
  .help .support-form .form .btn-wrapper {
    text-align: center;
    margin-bottom: 40px;
  }
  .help .support-form .form .btn-wrapper .submit-btn {
    width: 100%;
  }
}
.billings h5 {
  font-size: 20px;
  font-weight: 600;
  color: #212121;
  margin-top: 40px;
  margin-bottom: 25px;
}
.billings .bill-list {
  border: 1px solid rgba(33, 33, 33, 0.2);
  border-radius: 10px;
  padding: 25px;
}
.billings .bill-list .bill {
  padding-bottom: 16px;
  margin-bottom: 25px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
}
.billings .bill-list .bill:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}
.billings .bill-list .bill .top .name {
  font-weight: 600;
  color: #212121;
  line-height: 145%;
}
.billings .bill-list .bill .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.billings .bill-list .bill .bottom .all-fields {
  display: flex;
  align-items: flex-start;
  gap: 50px;
  margin-top: 10px;
}
.billings .bill-list .bill .bottom .all-fields .sub-field .heading {
  font-size: 14px;
  color: rgba(33, 33, 33, 0.6);
  line-height: 145%;
}
.billings .bill-list .bill .bottom .all-fields .sub-field .data {
  margin-top: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #212121;
  line-height: 145%;
}
.billings .bill-list .bill .bottom .invoice-btn {
  border-radius: 7px;
  border: 1px solid rgba(33, 33, 33, 0.2);
  padding: 9px 29px;
}

@media only screen and (max-width: 767px) {
  .billings h5 {
    display: none;
  }
  .billings .bill-list {
    border: none;
    border-radius: 10px;
    padding: 0;
    margin-top: 35px;
    margin-bottom: 10px;
  }
  .billings .bill-list .bill {
    padding: 14px 16px;
    margin-bottom: 25px;
    border: 1px solid rgba(33, 33, 33, 0.2);
    border-radius: 7px;
  }
  .billings .bill-list .bill .bottom {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .billings .bill-list .bill .bottom .all-fields {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin-top: 10px;
  }
  .billings .bill-list .bill .bottom .all-fields .sub-field .heading {
    font-size: 14px;
    color: rgba(33, 33, 33, 0.6);
    line-height: 145%;
  }
  .billings .bill-list .bill .bottom .all-fields .sub-field .data {
    margin-top: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #212121;
    line-height: 145%;
  }
  .billings .bill-list .bill .bottom .invoice-btn {
    width: 100%;
  }
}
.subscriptions h5 {
  font-size: 20px;
  font-weight: 600;
  color: #212121;
  margin-top: 40px;
  margin-bottom: 18px;
}
.subscriptions .subs-list {
  border: 1px solid rgba(33, 33, 33, 0.2);
  border-radius: 10px;
  padding: 25px;
}
.subscriptions .subs-list .sub {
  padding-bottom: 16px;
  margin-bottom: 25px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
}
.subscriptions .subs-list .sub:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}
.subscriptions .subs-list .sub .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.subscriptions .subs-list .sub .top .name {
  font-weight: 600;
  color: #212121;
  line-height: 145%;
}
.subscriptions .subs-list .sub .bottom {
  display: flex;
  align-items: flex-start;
  gap: 50px;
  margin-top: 10px;
}
.subscriptions .subs-list .sub .bottom .sub-field .heading {
  font-size: 14px;
  color: rgba(33, 33, 33, 0.6);
  line-height: 145%;
}
.subscriptions .subs-list .sub .bottom .sub-field .data {
  margin-top: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #212121;
  line-height: 145%;
}

@media only screen and (max-width: 767px) {
  .subscriptions h5 {
    display: none;
  }
  .subscriptions .subs-list {
    border: none;
    margin-top: 35px;
    padding: 0;
    padding-bottom: 10px;
  }
  .subscriptions .subs-list .sub {
    padding: 14px 16px;
    margin-bottom: 25px;
    border: 1px solid rgba(33, 33, 33, 0.2);
    border-radius: 7px;
  }
  .subscriptions .subs-list .sub:last-child {
    margin-bottom: 50px;
  }
  .subscriptions .subs-list .sub .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .subscriptions .subs-list .sub .top .name {
    font-weight: 600;
    color: #212121;
    line-height: 145%;
  }
  .subscriptions .subs-list .sub .bottom {
    flex-direction: column;
    gap: 16px;
    margin-top: 10px;
  }
  .subscriptions .subs-list .sub .bottom .sub-field .heading {
    font-size: 14px;
    color: rgba(33, 33, 33, 0.6);
    line-height: 145%;
  }
  .subscriptions .subs-list .sub .bottom .sub-field .data {
    margin-top: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #212121;
    line-height: 145%;
  }
}